import React, { useState, useEffect } from 'react';
import cloud1 from '../../assets/cloud1.png';
import cloud2 from '../../assets/cloud2.png';
import cloud3 from '../../assets/cloud3.png';
import './Hero.css';
import Carousel from '../Carousel';

const slides = [
    {
      heading: "Driving Efficiency",
      text: "From streamlined organization to precision execution, GEMCO ensures seamless project flow, optimizing resources and timelines for unparalleled efficiency in MEP construction."
    },
    {
      heading: "Defining Quality",
      text: "Our commitment to quality is driven by experienced professionals ensuring precision in every project."
    },
    {
      heading: "Ensuring Safety",
      text: "Prioritizing safety with a dedicated focus on best practices, fostering a robust safety culture in every project for the well-being of our team and our partners."
    },
    // Add more slides as needed
];

const Hero = () => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const nextSlide = () => {
      setCurrentSlide((prev) => (prev + 1) % slides.length);
    };
  
    const prevSlide = () => {
      setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
    };
  
    // Automatically switch to the next slide every 3 seconds
    useEffect(() => {
      const intervalId = setInterval(nextSlide, 2500);
  
      return () => clearInterval(intervalId);
    }, [currentSlide]);

    return (
        <div className='hero' id="home">
            <img className='cloud1' src={cloud1} alt="cloud1" />
            <img className='cloud2' src={cloud2} alt="cloud2" />
            <img className='cloud3' src={cloud3} alt="cloud3" />
            <div className='content'>
                <div className="carousel-container">
                    <div className="carousel" style={{ transform: `translateX(${-currentSlide * 100}%)` }}>
                        {slides.map((slide, index) => (
                            <div key={index} className={`slide ${index === currentSlide ? 'active' : ''}`}>
                                <h2>{slide.heading}</h2><br/>
                                <p>{slide.text}</p>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="carousel-arrows">
                    <button className='prevSlide' onClick={prevSlide}>
                        <i className="fa fa-chevron-left"></i>
                    </button>
                    <button className='nextSlide' onClick={nextSlide}>
                        <i className="fa fa-chevron-right"></i>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Hero;
