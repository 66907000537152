import React from 'react'
import contact from '../assets/contact.png'
import map from '../assets/map.png'
import phone from '../assets/phone.png'
import contact2 from '../assets/contact2.png'
import './Contact.css'

const Contact = () => {
  return (
    <div className='contact'>
        <div className='container contact-container'>
            <div className='contact-txt'>
                <h1 className='contact-heading'>Stay In Touch</h1>
                <p>Our vision is to be the foremost leader in delivering high-quality MEP services, setting new benchmarks for excellence in the industry. Our mission is to consistently.</p>
                <img className='contactImg' src={contact} />
                <p className='map-text'>
                    <img className='mapimg' src={map} /> +124 328 5412
                </p>
                <p className='phone-text'>
                    <img className='phone' src={phone} /> 
                    Office No 212, Ibn Batuta Gate office Building Jabal Ali Village, Dubai
                </p>
            </div>
            <div className='contact-logo'>
                <div className='nametag'>
                    Name
                    <div class="white-rectangle">
                        <p class="name">John Doe</p>
                    </div>
                </div><br/>
                <div className='wr2'>
                    <div class="white-rectangle-2">
                            <p class="name">Subscribe to join Family</p>
                    </div>
                </div><br/>
                <div className='contact2'>
                     <img className='contactimg2' src={contact2} /> 
                </div>
            </div>
        </div>
    </div>
  )
}

export default Contact