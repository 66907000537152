import React from 'react'
import VisionImg from '../../assets/vision.png'
import VisionImg1 from '../../assets/vision1.png'
import Rectangle1 from '../../assets/Rectangle1.png'
import Rectangle2 from '../../assets/Rectangle2.png'
import './Vision.css'

const Vision = () => {
  return (
    <div className='vision'>
        <div className='container'>
            <div className='vision-column'>
                <img className='visionline' src={VisionImg1}/>
                <img className='visionmainimg' src={VisionImg}/>
                <img className='rectangle1' src={Rectangle1}/>
                <img className='rectangle2' src={Rectangle2}/>
                <div className='orbit'>
                    <div className='rocket'></div>
                    <div className='window'></div>
                </div>
            </div>
            <div>
                <div className='vision-text-header'>
                    <h2 className='vision-heading2'>Vision and Mission</h2>
                </div><br></br>
                <div className='vision-description'>
                    <p>Vision and Mission: Our vision is to be the foremost leader in delivering high-quality MEP services, setting new benchmarks for excellence in the industry. Our mission is to consistently exceed client expectations by delivering top-notch Mep solutions that prioritize quality, safety, and timely project completion. We strive to employ sustainable and efficient methods that have a positive impact on the environment and contribute to the overall well-being of the community.</p>
                    <p>Core Values: At Gemco, our core values serve as the guiding principles that shape our operations and differentiate us from the competition. These values include integrity, professionalism, customer focus, teamwork, innovation, safety, excellence, accountability, environmental responsibility, and long-term partnerships. We uphold these values in every aspect of our work, ensuring that our clients receive the highest level of service and satisfaction.</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Vision