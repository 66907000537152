// LeafletMap.js
import React, { useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';

import AdcanPharma from '../../assets/AdcanPharma.jpg';
import Arjaan from '../../assets/Arjaan.jpeg';
import Bvlgari from '../../assets/Bvlgari.jpeg';
import FlyDubaiHQ from '../../assets/FlyDubaiHQ.jpg';
import HiltonRAK from '../../assets/HiltonRAK.jpeg';
import Moroccopaviloin from '../../assets/Moroccopaviloin.jpg';
import SereniaResidences from '../../assets/Sereniaresidences.jpeg';
import ZaabelEmpowerDCP from '../../assets/ZaabelEmpowerDCP.jpeg';
import ZARAStorages from '../../assets/ZARAStorages.png';
import MobilityPavilion from '../../assets/MobilityPavilion.jpeg';
import MarsaAl from '../../assets/MarsaAl.jpeg';
import DubaiLand from '../../assets/DubaiLand.jpeg';
import AvenueMallCity from '../../assets/AvenueMallCity.jpg';
import FamilyHotelSA2Blue from '../../assets/FamilyHotelSA2Blue.jpg';
import ClevelandClinic from '../../assets/ClevelandClinic.png';
import DubaiSportsComplex from '../../assets/DubaiSportsComplex.jpg';
import DubaiInternationalAirport from '../../assets/DubaiInternationalAirport.jpg';
import DubaiInternationAirportAirportConcourse from '../../assets/DubaiInternationAirportAirportConcourse.jpeg';
import MasdarHeadQuarters from '../../assets/MasdarHeadQuarters.jpeg';
import ToyotaServicecenter from '../../assets/ToyotaServicecenter.jpeg';
import Ruwaisresidential from '../../assets/Ruwaisresidential.jpg';
import AlSufouhtransitsystem from '../../assets/AlSufouhtransitsystem.jpeg';
import IKEAfestivalplaza from '../../assets/IKEAfestivalplaza.jpg';
import EtisalatDataCenter from '../../assets/EtisalatDataCenter.jpg';
import "leaflet/dist/leaflet.css";

const customIcon = L.icon({
  iconUrl: 'https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41]
});

function LeafletMap({ selectedRent, onMarkerClick }) {
  const positions = [
    { lat: 24.2990441, lng: 54.4867121 },
    { lat: 25.2523644, lng: 55.3014385 },
    { lat: 25.2095105, lng: 55.2360019 },
    { lat: 25.1456109, lng: 55.4719230 },
    { lat: 25.8135584, lng: 55.9577472 },
    { lat: 24.9633007, lng: 55.1523103 },
    { lat: 25.1322299, lng: 55.1522667 },
    { lat: 25.2198658, lng: 55.2895139 },
    { lat: 25.1178661, lng: 55.1985295 },
    { lat: 25.260079, lng: 55.309572 },
    { lat: 25.089101, lng: 55.385848 },
    { lat: 25.207853, lng: 55.262782 },
    { lat: 25.082197, lng: 55.121699 },
    { lat: 24.497394, lng: 54.387710 },
    { lat: 25.051821, lng: 55.317800 },
    { lat: 25.248178, lng: 55.352074 },
    { lat: 25.263909, lng: 55.338864 },
    { lat: 24.426673, lng: 54.618786 },
    { lat: 25.208587, lng: 55.361903 },
    { lat: 24.090785, lng: 52.642752 },
    { lat: 25.115810, lng: 55.185502 },
    { lat: 25.028677, lng: 55.106270 },
    { lat: 25.028221, lng: 55.120803 },
  ];

  const rent = ['At Adcan Pharma, GEMCO undertook a pivotal role in executing crucial mechanical works, specializing in steam and condensation systems. Our dedicated efforts, carried under the contract with ALEC.', 
                '"In this ongoing refurbishment project with M/s ALEMCO and consultant M/s Arif & Bintoak, GEMCO is undertaking a comprehensive scope of work. Our tasks involve the replacement of the entire HVAC systems within the Arjaan hotel building, transforming it into a residential building to meet the latest requirements. This project underscores our commitment to revitalizing existing structures and adapting them to evolving standards for enhanced functionality and modern living.'
                , 'At the prestigious Bvlgari Hotel, Residences, and Mansions project, GEMCO undertook the fabrication and installation of the mechanical system within the central Chilled Water (CHW) plant room. Our scope of work extended to encompass MEP installations for individual mansions, the residential building, and hotel buildings, showcasing our expertise in delivering comprehensive solutions across diverse components of this luxurious development'
                , 'GEMCO proudly secured the Flydubai HQ project awarded by M/s Transgulf. Our responsibilities encompassed the installation of the Mechanical plant room and various other mechanical and electrical systems within the office building and the tunnel works.'
                , 'At RAK Hilton, GEMCO excelled in the retrofit project under M/s ALEC by implementing effective solutions and ensuring timely completion. We strategically upgraded the plant room, seamlessly meeting the latest requirements, and executed a swift refurbishment of the entire cooling system. Our practical approach and commitment to efficiency not only enhanced the projects functionality but also underscored our dedication to delivering impactful results within the stipulated time frame'
                , '"Executing this prestigious project under Al Shafar Contracting amidst fast-paced works, GEMCO showcased its efficiency and expertise. Our team had the opportunity to contribute to the floor and roof mechanical systems of this pavilion, completing the task in a remarkably short timeframe. This project stands as a testament to GEMCO ability to deliver quality results under challenging timelines.'
                , '"GEMCO successfully undertook and completed the entire mechanical systems project under M/s Al Futtaim Engineering for a prestigious residential building comprising three premium residences in Palm Jumeirah. Our commitment to excellence and seamless project delivery is reflected in the successful execution of this high-end development'
                , 'At Zaabeel DCP, GEMCO played a crucial role in achieving the successful completion of mechanical systems installation, with a primary focus on the Chilled Water (CHW) pipe system featuring a maximum pipe size of 1200 mm dia. Our pivotal contributions were instrumental in ensuring the timely completion of the project, executed under the esteemed main contractor M/s AGE.'
                , 'At Zara Storages, an ongoing MEP project managed by M/s ALEC with M/s WSP as the consultant, GEMCO is actively engaged in meeting demanding timelines. Our comprehensive scope involves not only relocating existing MEP systems to facilitate a new storage space on the roof for ZARA but also encompasses the supply and installation of new MEP systems, ELV, Firefighting, and BMS integration for ZARA storage. This project exemplifies our unwavering commitment to punctual delivery while seamlessly integrating MEP solutions to meet our clients needs.'
                ,'mobility pavilion, Expo 2020 ', 'Marsa Al Seef, Al Seef', 'Dubai Land Residential Complex district cooling project'
                , 'Avenue Mall City Walk Phase - 2', 'Family Hotel and SA2, Blue Waters Dubai', 'Cleveland Clinic, Al Maryah Island'
                , 'Dubai Sports Complex', 'Dubai International Airport Concourse 3, Dubai', 'Dubai Internation Airport, Airport Concourse 4 Expansion, Dubai',
                'Masdar Head Quarters, Masdar City, Abu Dhabi', 'Toyota Service Centre, Dubai', 'Ruwais Housing Complex', 
                'Al Sufouh transit system', 'IKEA Festival Plaza', 'Etisalat Data center'];

  const images = [AdcanPharma, Arjaan, Bvlgari, FlyDubaiHQ, HiltonRAK, Moroccopaviloin, SereniaResidences
                  , ZaabelEmpowerDCP, ZARAStorages, MobilityPavilion, MarsaAl, DubaiLand, AvenueMallCity
                  , FamilyHotelSA2Blue, ClevelandClinic, DubaiSportsComplex, DubaiInternationalAirport,
                  DubaiInternationAirportAirportConcourse, MasdarHeadQuarters, ToyotaServicecenter, Ruwaisresidential
                  , AlSufouhtransitsystem, IKEAfestivalplaza, EtisalatDataCenter];

  const mapRef = useRef(null);

  useEffect(() => {
    if (selectedRent) {
      const index = rent.indexOf(selectedRent);
      if (index !== -1) {
        const position = positions[index];
        mapRef.current.setView(position, 14);
      }
    }
  }, [selectedRent]);

  return (
    <MapContainer center={[25.2695, 55.3052]} zoom={8} scrollWheelZoom={false} ref={mapRef}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {positions.map((position, index) => (
        <Marker key={index} position={[position.lat, position.lng]} icon={customIcon} eventHandlers={{ click: () => onMarkerClick(rent[index]) }}>
          <Popup>
            <div>
              <img src={images[index]} alt="Marker Image" style={{ height: '100px', width: '170px' }} />
              <p>{rent[index]}</p>
            </div>
          </Popup>
        </Marker>
      ))}
    </MapContainer>
  );
}

export default LeafletMap;
