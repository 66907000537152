import React, {useState} from 'react'
import { Link } from 'react-scroll';
import {HiOutlineMenuAlt4} from 'react-icons/hi'
import logo from '../../assets/Logo.png'
import logotext from '../../assets/Logotext.png'
import nav1 from '../../assets/nav1.png'
import nav2 from '../../assets/nav2.png'
import nav3 from '../../assets/nav3.png'
import './Navbar.css'

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleSidebar = () => {
      setIsOpen(!isOpen);
    };
  
    const closeSidebar = () => {
      setIsOpen(false);
    };

    const handleLinkClick = () => {
        closeSidebar();
      };

    return (
        <div className='navbar' >
            <div className='container'>
                <div className={isOpen ? "toggle-btn hidden" : "toggle-btn"} onClick={toggleSidebar}><HiOutlineMenuAlt4 className='icon' /></div>
                <div className={isOpen ? "sidebar open" : "sidebar"}>
                    <button className="close-btn" onClick={closeSidebar}>X</button>
                    <div className='nav-container'>
                        <div className='nav-text'>
                            <nav>
                                <ul>
                                    <li><Link to="home" spy={true} smooth={true} offset={50} duration={500} onClick={handleLinkClick}>HOME</Link></li>
                                    <li><Link to="about" spy={true} smooth={true} offset={50} duration={500} onClick={handleLinkClick}>ABOUT US</Link></li>
                                    <li><Link to="service" spy={true} smooth={true} offset={50} duration={500} onClick={handleLinkClick}>SERVICE</Link></li>
                                    <li><Link to="projects" spy={true} smooth={true} offset={50} duration={500} onClick={handleLinkClick}>PROJECTS</Link></li>
                                    <li><a href="contact">CONTACT US</a></li>
                                </ul>
                            </nav>
                        </div>
                        <div className='nav-image'>
                            <div class="sidebarrec1">
                                <img className='nav1' src={nav1}/>
                            </div>
                            <div class="sidebarrec2">
                                <img className='nav2' src={nav2}/>
                            </div>
                            <div class="sidebarrec3">
                                <img className='nav3' src={nav3}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='logotxt'>
                    <a href='/'><img className='logo' src={logo}/></a>
                    <a href='/'><img className='logotext' src={logotext}/></a> 
                </div>
            </div>
        </div>
    )
}

export default Navbar