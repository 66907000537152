import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Hero from './components/Hero/Hero';
import About from './components/About/About';
import Vision from './components/Vision/Vision';
import Service from './components/Service/Service';
import Certificate from './components/Certificate/Certificate';
import Clients from './components/Clients/Clients';
import Corevalues from './components/Corevalues/Corevalues';
import Contact from './components/Contact';

function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={
          <>
            <Hero />
            <About />
            <Vision />
            <Service />
            <Certificate />
            <Clients />
            <Corevalues />
          </>
        } />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </>
  );
}

export default App;
