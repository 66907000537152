import React, {useState, useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'; // You can also use <link> for styles
import LightmodeBulb from '../../assets/amico-light.png'
import DarkmodeBulb from '../../assets/amico-dark.png'
import Lightmodearrow from '../../assets/Vector-light.png'
import Darkmodearrow from '../../assets/Vector-dark.png'
import './About.css'

const About = () => {

  const [theme, setTheme] = useState('');
  const [showPara, setShowPara] = useState(true);
  const [isHeaderAnimated, setIsHeaderAnimated] = useState(false);

  const [showSecondText, setShowSecondText] = useState(false);
 

  const ToggleTheme = () => {
    setTheme(theme === '' ? 'light-theme' : '');
    setShowPara(!showPara); // Toggle the visibility of about-para1
    setIsHeaderAnimated(!isHeaderAnimated);
  };

  const ToggleText = () => {
    setShowSecondText(!showSecondText);
  };

  useEffect(() => {
    document.body.className = theme;
  }, [theme]);

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  
  return (
    <div className='about about-dark' id='about' theme={theme} ToggleTheme={ToggleTheme}>
         <div className='container'>
        <div className='bulb-text'>
        <div className={`bulb-text-header ${isHeaderAnimated ? 'slide-down' : ''}`} 
            style={{ transform: `translateY(${isHeaderAnimated ? '80%' : '0'})` }}>
            <h2 className='about-heading2' data-aos='fade-right'>
              About Us
            </h2>
          </div>
          <br />
          <div className='about-text'>
            {showPara && ( // Conditional rendering based on showPara state
              <p className='about-para1'>
                "Welcome to Gandhi Electromechanical Company (GEMCO), a trusted provider of MEP solutions in the UAE region. With over two decades of experience, we have established ourselves as a leader in the industry, known for our commitment to quality, reliability, and customer satisfaction"
              </p>
            )}
            <br/>
            <p className={`${isHeaderAnimated ? 'add-border' : ''} about-para2`}>
  {showSecondText
    ? (
        <>
          History: 1996: Partnership as 'BLUE SKY' – Mechanical and Electrical Services <br />
          2000: Establishment of Everest Electromechanical Company – Core Mechanical Services <br />
          2005: Rebranding as Gandhi Group –Electrical and Mechanical Services <br />
          2010: Completion of multiple landmark projects including Airport & Dubai Metro <br />
          2015: Expansion in to Facility Maintenance & Retrofit works <br />
          2020: Proud participant in DUBAI EXPO on multiple projects. <br />
          2023: Commencing of Trading & Flooring works
        </>
      )
    : "Company Overview: At GEMCO, we pride ourselves on delivering high-quality MEP solutions with an unwavering focus on safety. Our team of experienced professionals follows stringent quality control procedures and adheres to rigorous safety regulations to ensure that every project is executed with precision and care. We leverage our expertise, cutting-edge technologies, and industry best practices to provide exceptional results to our clients"}
</p>

          </div>
        </div>
        <div className='bulb-column'>
          <div data-aos='fade-down'>
            <h3 className='bulb-heading'>Touch The Bulb</h3>
            <img
              className='Lightmodearrow'
              data-aos='fade-left'
              src={theme === 'light-theme' ? Darkmodearrow : Lightmodearrow}
            />
            <div className='bulb-image'>
              <img
                className='bulb'
                data-aos='fade-left'
                onClick={() => {
                  ToggleTheme();
                  ToggleText();
                }}
                src={theme === 'light-theme' ? DarkmodeBulb : LightmodeBulb}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About