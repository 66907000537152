// Corevalues.js
import React, { useState } from 'react';

import AdcanPharma from '../../assets/AdcanPharma.jpg';
import Arjaan from '../../assets/Arjaan.jpeg';
import Bvlgari from '../../assets/Bvlgari.jpeg';
import FlyDubaiHQ from '../../assets/FlyDubaiHQ.jpg';
import HiltonRAK from '../../assets/HiltonRAK.jpeg';
import Moroccopaviloin from '../../assets/Moroccopaviloin.jpg';
import SereniaResidences from '../../assets/Sereniaresidences.jpeg';
import ZaabelEmpowerDCP from '../../assets/ZaabelEmpowerDCP.jpeg';
import ZARAStorages from '../../assets/ZARAStorages.png';
import MobilityPavilion from '../../assets/MobilityPavilion.jpeg';
import MarsaAl from '../../assets/MarsaAl.jpeg';
import DubaiLand from '../../assets/DubaiLand.jpeg';
import AvenueMallCity from '../../assets/AvenueMallCity.jpg';
import FamilyHotelSA2Blue from '../../assets/FamilyHotelSA2Blue.jpg';
import ClevelandClinic from '../../assets/ClevelandClinic.png';
import DubaiSportsComplex from '../../assets/DubaiSportsComplex.jpg';
import DubaiInternationalAirport from '../../assets/DubaiInternationalAirport.jpg';
import DubaiInternationAirportAirportConcourse from '../../assets/DubaiInternationAirportAirportConcourse.jpeg';
import MasdarHeadQuarters from '../../assets/MasdarHeadQuarters.jpeg';
import ToyotaServicecenter from '../../assets/ToyotaServicecenter.jpeg';
import Ruwaisresidential from '../../assets/Ruwaisresidential.jpg';
import AlSufouhtransitsystem from '../../assets/AlSufouhtransitsystem.jpeg';
import IKEAfestivalplaza from '../../assets/IKEAfestivalplaza.jpg';
import EtisalatDataCenter from '../../assets/EtisalatDataCenter.jpg';

import './Corevalues.css';
import LeafletMap from './LeafletMap';

const Corevalues = () => {
  const [selectedRent, setSelectedRent] = useState(null);

  const handleProjectClick = (rent) => {
    setSelectedRent(rent);
  };

  return (
    <div className='corevalue' id="projects">
      <h1 class="container projects-heading" >Our Projects</h1> 
      <div className='container corevalue-container'>
        <div className='project'>
          <div id='project1' onClick={() => handleProjectClick('At Adcan Pharma, GEMCO undertook a pivotal role in executing crucial mechanical works, specializing in steam and condensation systems. Our dedicated efforts, carried under the contract with ALEC.')}>
            <img className='AdcanPharma' src={AdcanPharma} alt="AdcanPharma"/>
            <h2 className='projectheading'>AdcanPharma</h2>
            <p>Ongoing</p>
          </div>
          <div id='project2' onClick={() => handleProjectClick('"In this ongoing refurbishment project with M/s ALEMCO and consultant M/s Arif & Bintoak, GEMCO is undertaking a comprehensive scope of work. Our tasks involve the replacement of the entire HVAC systems within the Arjaan hotel building, transforming it into a residential building to meet the latest requirements. This project underscores our commitment to revitalizing existing structures and adapting them to evolving standards for enhanced functionality and modern living.')}>
            <img className='Arjaan Rotana' src={Arjaan} alt="Arjaan Rotana"/>
            <h2 className='projectheading'>Arjaan Rotana</h2>
            <p>Ongoing</p>
          </div>
          <div id='project3' onClick={() => handleProjectClick('At the prestigious Bvlgari Hotel, Residences, and Mansions project, GEMCO undertook the fabrication and installation of the mechanical system within the central Chilled Water (CHW) plant room. Our scope of work extended to encompass MEP installations for individual mansions, the residential building, and hotel buildings, showcasing our expertise in delivering comprehensive solutions across diverse components of this luxurious development')}>
            <img className='Bvlgari' src={Bvlgari} alt="Bvlgari"/>
            <h2 className='projectheading'>Bvlgari</h2>
            <p>Completed</p>
          </div>
          <div id='project4' onClick={() => handleProjectClick('GEMCO proudly secured the Flydubai HQ project awarded by M/s Transgulf. Our responsibilities encompassed the installation of the Mechanical plant room and various other mechanical and electrical systems within the office building and the tunnel works.')}>
            <img className='Fly Dubai HQ' src={FlyDubaiHQ} alt="Fly Dubai HQ"/>
            <h2 className='projectheading'>Fly Dubai HQ</h2>
            <p>Completed</p>
          </div>
          <div id='project5' onClick={() => handleProjectClick('At RAK Hilton, GEMCO excelled in the retrofit project under M/s ALEC by implementing effective solutions and ensuring timely completion. We strategically upgraded the plant room, seamlessly meeting the latest requirements, and executed a swift refurbishment of the entire cooling system. Our practical approach and commitment to efficiency not only enhanced the projects functionality but also underscored our dedication to delivering impactful results within the stipulated time frame')}>
            <img className='Hilton - RAK' src={HiltonRAK} alt="Hilton - RAK"/>
            <h2 className='projectheading'>Hilton - RAK</h2>
            <p>Completed</p>
          </div>
          <div id='project6' onClick={() => handleProjectClick('"Executing this prestigious project under Al Shafar Contracting amidst fast-paced works, GEMCO showcased its efficiency and expertise. Our team had the opportunity to contribute to the floor and roof mechanical systems of this pavilion, completing the task in a remarkably short timeframe. This project stands as a testament to GEMCO ability to deliver quality results under challenging timelines.')}>
            <img className='Morocco pavilion, Dubai Expo' src={Moroccopaviloin} alt="Morocco pavilion, Dubai Expo"/>
            <h2 className='projectheading'>Morocco pavilion, Dubai Expo</h2>
            <p>Completed</p>
          </div>
          <div id='project7' onClick={() => handleProjectClick('"GEMCO successfully undertook and completed the entire mechanical systems project under M/s Al Futtaim Engineering for a prestigious residential building comprising three premium residences in Palm Jumeirah. Our commitment to excellence and seamless project delivery is reflected in the successful execution of this high-end development')}>
            <img className='Serenia Residences' src={SereniaResidences} alt="Serenia Residences"/>
            <h2 className='projectheading'>Serenia Residences</h2>
            <p>Completed</p>
          </div>
          <div id='project8' onClick={() => handleProjectClick('At Zaabeel DCP, GEMCO played a crucial role in achieving the successful completion of mechanical systems installation, with a primary focus on the Chilled Water (CHW) pipe system featuring a maximum pipe size of 1200 mm dia. Our pivotal contributions were instrumental in ensuring the timely completion of the project, executed under the esteemed main contractor M/s AGE.')}>
            <img className='Zabeel Empower DCP' src={ZaabelEmpowerDCP} alt="Zabeel Empower DCP"/>
            <h2 className='projectheading'>Zabeel Empower DCP</h2>
            <p>Completed</p>
          </div>
          <div id='project9' onClick={() => handleProjectClick('At Zara Storages, an ongoing MEP project managed by M/s ALEC with M/s WSP as the consultant, GEMCO is actively engaged in meeting demanding timelines. Our comprehensive scope involves not only relocating existing MEP systems to facilitate a new storage space on the roof for ZARA but also encompasses the supply and installation of new MEP systems, ELV, Firefighting, and BMS integration for ZARA storage. This project exemplifies our unwavering commitment to punctual delivery while seamlessly integrating MEP solutions to meet our clients needs.')}>
            <img className='Zara Storages MOE' src={ZARAStorages} alt="Zara Storages MOE"/>
            <h2 className='projectheading'>Zara Storages MOE</h2>
            <p>Ongoing</p>
          </div>
          <div id='project10' onClick={() => handleProjectClick('mobility pavilion, Expo 2020')}>
            <img className='mobility pavilion, Expo 2020' src={MobilityPavilion} alt="mobility pavilion, Expo 2020"/>
            <h2 className='projectheading'>mobility pavilion, Expo 2020</h2>
            <p>Completed</p>
          </div>
          <div id='project11' onClick={() => handleProjectClick('Marsa Al Seef, Al Seef')}>
            <img className='Marsa Al Seef, Al Seef' src={MarsaAl} alt="Marsa Al Seef, Al Seef"/>
            <h2 className='projectheading'>Marsa Al Seef, Al Seef</h2>
            <p>Completed</p>
          </div>
          <div id='project12' onClick={() => handleProjectClick('Dubai Land Residential Complex district cooling project')}>
            <img className='Dubai Land Residential Complex district cooling project' src={DubaiLand} alt="Dubai Land Residential Complex district cooling project"/>
            <h2 className='projectheading'>Dubai Land Residential Complex district cooling project</h2>
            <p>Completed</p>
          </div>
          <div id='project13' onClick={() => handleProjectClick('Avenue Mall City Walk Phase - 2')}>
            <img className='Avenue Mall City Walk Phase - 2' src={AvenueMallCity} alt="Avenue Mall City Walk Phase - 2"/>
            <h2 className='projectheading'>Avenue Mall City Walk Phase - 2</h2>
            <p>Completed</p>
          </div>
          <div id='project14' onClick={() => handleProjectClick('Family Hotel and SA2, Blue Waters Dubai')}>
            <img className='Family Hotel and SA2, Blue Waters Dubai' src={FamilyHotelSA2Blue} alt="Family Hotel and SA2, Blue Waters Dubai"/>
            <h2 className='projectheading'>Family Hotel and SA2, Blue Waters Dubai</h2>
            <p>Completed</p>
          </div>
          <div id='project15' onClick={() => handleProjectClick('Cleveland Clinic, Al Maryah Island')}>
            <img className='Cleveland Clinic, Al Maryah Island' src={ClevelandClinic} alt="Cleveland Clinic, Al Maryah Island"/>
            <h2 className='projectheading'>Cleveland Clinic, Al Maryah Island</h2>
            <p>Completed</p>
          </div>
          <div id='project16' onClick={() => handleProjectClick('Dubai Sports Complex')}>
            <img className='Dubai Sports Complex' src={DubaiSportsComplex} alt="Dubai Sports Complex"/>
            <h2 className='projectheading'>Dubai Sports Complex</h2>
            <p>Completed</p>
          </div>
          <div id='project16' onClick={() => handleProjectClick('Dubai International Airport Concourse 3, Dubai')}>
            <img className='Dubai International Airport Concourse 3, Dubai' src={DubaiInternationalAirport} alt="Dubai International Airport Concourse 3, Dubai"/>
            <h2 className='projectheading'>Dubai International Airport Concourse 3, Dubai</h2>
            <p>Completed</p>
          </div>
          <div id='project16' onClick={() => handleProjectClick('Dubai Internation Airport, Airport Concourse 4 Expansion, Dubai')}>
            <img className='Dubai Internation Airport, Airport Concourse 4 Expansion, Dubai' src={DubaiInternationAirportAirportConcourse} alt="Dubai Internation Airport, Airport Concourse 4 Expansion, Dubai"/>
            <h2 className='projectheading'>Dubai Internation Airport, Airport Concourse 4 Expansion, Dubai</h2>
            <p>Completed</p>
          </div>
          <div id='project16' onClick={() => handleProjectClick('Masdar Head Quarters, Masdar City, Abu Dhabi')}>
            <img className='Masdar Head Quarters, Masdar City, Abu Dhabi' src={MasdarHeadQuarters} alt="Masdar Head Quarters, Masdar City, Abu Dhabi"/>
            <h2 className='projectheading'>Masdar Head Quarters, Masdar City, Abu Dhabi</h2>
            <p>Completed</p>
          </div>
          <div id='project16' onClick={() => handleProjectClick('Toyota Service Centre, Dubai')}>
            <img className='Toyota Service Centre, Dubai' src={ToyotaServicecenter} alt="Toyota Service Centre, Dubai"/>
            <h2 className='projectheading'>Toyota Service Centre, Dubai</h2>
            <p>Completed</p>
          </div>
          <div id='project6' onClick={() => handleProjectClick('Ruwais Housing Complex')}>
            <img className='Ruwais Housing Complex' src={Ruwaisresidential} alt="Ruwais Housing Complex"/>
            <h2 className='projectheading'>Ruwais Housing Complex</h2>
            <p>Completed</p>
          </div>
          <div id='project6' onClick={() => handleProjectClick('Al Sufouh transit system')}>
            <img className='Al Sufouh transit system' src={AlSufouhtransitsystem} alt="Al Sufouh transit system"/>
            <h2 className='projectheading'>Al Sufouh transit system</h2>
            <p>Completed</p>
          </div>
          <div id='project6' onClick={() => handleProjectClick('IKEA Festival Plaza')}>
            <img className='IKEA Festival Plaza' src={IKEAfestivalplaza} alt="IKEA Festival Plaza"/>
            <h2 className='projectheading'>IKEA Festival Plaza</h2>
            <p>Completed</p>
          </div>
          <div id='project6' onClick={() => handleProjectClick('Etisalat Data center')}>
            <img className='Etisalat Data center' src={EtisalatDataCenter} alt="Etisalat Data center"/>
            <h2 className='projectheading'>Etisalat Data center</h2>
            <p>Completed</p>
          </div>

        </div>
        <div className='map' id="map">
          <LeafletMap selectedRent={selectedRent} onMarkerClick={handleProjectClick} />
        </div>
      </div>
    </div>
  );
};

export default Corevalues;
