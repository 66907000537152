import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Carousel.css'; // Import your CSS file

const Carousel = () => {

  const carouselData  = [
    {
      imageUrl: require("../assets/MechanicalElectrical.jpg"),
      heading: 'Mechanical & Electrical Works',
      text: 'Mechanical & Electrical Works - We specialize in delivering comprehensive & effective services for a variety of projects spanning commercial, residential, and industrial sectors. Our seasoned team of experts excels in supply, installing, and maintaining highly efficient and reliable mechanical and electrical systems. Embracing a commitment to quality, safety, and industry standards, we customize solutions to meet the specific needs of each project',
    },
    {
      imageUrl: require("../assets/service3.png"),
      heading: 'MEP Retrofit and Refurbishment Works',
      text: 'Our MEP retrofit and refurbishment services are designed to transform existing MEP infrastructure. With a focus on optimizing performance and enhancing energy efficiency, we specialize in renovating and upgrading outdated systems to meet current standards and regulations. Our team of specialists conducts thorough assessments of the existing infrastructure, devises customized solutions, and executes precise upgrades to improve system functionality, sustainability, and cost-effectiveness.',
    },
    {
      imageUrl: require("../assets/FacilityMaintanace.jpg"),
      heading: 'Facility Maintenance',
      text: 'Our expertise extends beyond HVAC systems to encompass a comprehensive range of hard services, including civil works, such as granite and terrazzo installation & maintenance. Through regular inspections, preventive maintenance measures, and timely repairs, we guarantee the seamless operation of your heating, ventilation, and air conditioning systems, as well as other critical elements of your facility. Our flexible maintenance plans, tailored to your specific needs, allow you to maximize the longevity and efficiency of various equipment while minimizing downtime and costs',
    },
    {
      imageUrl: require("../assets/Trading.jpg"),
      heading: 'Specialist Products Trading',
      text: 'In addition to our extensive services, we specialize in the supply of Mechanical and Electrical works alongside an array of high-quality lighting products. Our commitment to excellence extends to every aspect of the trading process, ensuring that our clients receive top-notch solutions. Whether the provision of advanced Mechanical and Electrical components or cutting-edge lighting products, our dedicated team ensures that your specific requirements are met with precision. Explore our range of trading services to enhance your projects with quality products and comprehensive solutions',
    },
  ]

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000, // Set the duration for each slide in milliseconds (e.g., 3000ms or 3s)
  };

  return (
    <div className="carouselcontainer">
      <Slider {...settings}>
        {carouselData.map((slide, index) => (
          <div className='maincarousel' key={index}>
            <h4 className='carousel-heading'>{slide.heading}</h4>
            <div className='carouselbox'>            
              <p className='carousel-text'>{slide.text}</p>
              <img className='carousel-img' src={slide.imageUrl} alt={`Slide ${index + 1}`} />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Carousel;