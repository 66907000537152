import React from 'react'
import certificateiso1 from '../../assets/certificateiso1.png'
import certificateiso2 from '../../assets/certificateiso2.png'
import './Certificate.css'

const Certificate = () => {
  return (
    <div className='cerificate'>
        <div  className='container'>
          <div className='certificate-text'>
            <h1 className='certificate-heading'>Our Certifications & Our Policies</h1><br></br>
            <h2 className='certification-heading'>Certifications:</h2><br></br>
            <h5 className='isoname'>ISO 9001:2015 Certification</h5>
            <img className='certificateiso' src={certificateiso1} />
            <p>
            Our commitment to maintaining high standards in quality management. It ensures that our processes consistently meet customer requirements and regulatory standards
            </p>
            <br></br>
            <h5 className='isoname'>ISO 45001:2018 Certification</h5>
            <img className='certificateiso2' src={certificateiso2} />
            <p>
            Highlights our dedication to occupational health and safety. It demonstrates our proactive approach to creating a safe working environment for our employees and stakeholders
            </p><br></br>
            <h2 className='policy-heading'>Policies:</h2><br></br>
            <p>
              <strong style={{color:'black'}}>Ehs Policy:</strong><br></br>
              Our Environmental, Health, and Safety (EHS) policy outlines our pledge to conduct business in an environmentally sustainable and socially responsible manner. We prioritize the well-being of our employees and minimize our impact on the environment.
              <br></br>
              <strong style={{color:'black'}}>Housekeeping Program:</strong><br></br>
              Our Housekeeping Program ensures a clean and organized work environment. This not only enhances safety but also contributes to the overall efficiency and professionalism of our operations.
              <br></br>
              <strong style={{color:'black'}}>Hse Manual:</strong><br></br>
              The Health, Safety, and Environment (HSE) Manual provides comprehensive guidelines for maintaining a safe workplace. It covers protocols, procedures, and best practices to prevent accidents and protect the well-being of our workforce.
              <br></br>
              <strong style={{color:'black'}}>Worker Welfare Policy:</strong><br></br>
              Our Worker Welfare Policy underscores our commitment to the welfare of our employees. It encompasses fair labor practices, employee well-being, and measures to enhance the quality of work-life for our valued team members.
              <br></br>
            </p>
          </div>
        </div><br></br>
    </div>
  )
}

export default Certificate