// Clients.js

import React, { useState } from 'react';
import client1 from '../../assets/client1.png';
import client2 from '../../assets/client2.png';
import client3 from '../../assets/client3.png';
import client4 from '../../assets/client4.png';
import client5 from '../../assets/client5.png';
import client6 from '../../assets/client6.png';
import client7 from '../../assets/client7.png';
import client8 from '../../assets/client8.png';
import client9 from '../../assets/client9.png';
import client10 from '../../assets/client10.png';
import client11 from '../../assets/client11.png';
import client12 from '../../assets/client12.png';
import client13 from '../../assets/client13.png';
import client14 from '../../assets/client14.png';
import client15 from '../../assets/client15.png';
import './Clients.css';

const Clients = () => {
  const clientData = [
    { id: 1, className: 'client1' ,imgSrc: client1},
    { id: 2, className: 'client2' ,imgSrc: client2},
    { id: 3, className: 'client3' ,imgSrc: client3},
    { id: 4, className: 'client4' ,imgSrc: client4},
    { id: 5, className: 'client5' ,imgSrc: client5},
    { id: 6, className: 'client6' ,imgSrc: client6},
    { id: 7, className: 'client7' ,imgSrc: client7},
    { id: 8, className: 'client8' ,imgSrc: client8},
    { id: 9, className: 'client9' ,imgSrc: client9},
    { id: 10, className: 'client10' ,imgSrc: client10},
    { id: 11, className: 'client11' ,imgSrc: client11},
    { id: 12, className: 'client12' ,imgSrc: client12},
    { id: 13, className: 'client13' ,imgSrc: client13},
    { id: 14, className: 'client14' ,imgSrc: client14},
    { id: 15, className: 'client15' ,imgSrc: client15},
    // ... add more client data
  ];

  const [activeClientId, setActiveClientId] = useState(null);

  const handleClientImgClick = (clientId) => {
    setActiveClientId((prevClientId) => (prevClientId === clientId ? null : clientId));
  };

  const handleMouseMove = () => {
    setActiveClientId(null);
  };

  return (
    <div className='clients' onMouseMove={handleMouseMove}>
      <div className='container'>
        <h1 className='clients-heading'>Our Clients</h1><br></br>
        <div className="col-lg-12 col-md-12 col-sm-12 col-10">
          <div className="row">
            {clientData.map((client) => (
              <div
                key={client.id}
                className={`bank col-lg-2 col-md-3 col-12 col-sm-3 partners ${
                  activeClientId === client.id ? 'active' : ''
                }`}
              >
                <img
                  className={`clientimg ${client.className}`}
                  src={client.imgSrc}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Clients;
