import React from 'react'
import Carousel from '../Carousel';
import './Service.css'

const Service = () => {
  return (
    <div className='service' id="service">
        <div  className='container'>
          <h1 className='service-heading'>Our Services</h1><br></br>
          <Carousel/>
        </div>
    </div>
  )
}

export default Service